<template>
    <div>
        <a-page-header
        class="header_title"
        title=" "
        @back="() => $router.go(-1)"
        />
        <a-row :gutter="16">
            <a-col :span="4" v-for="item in productData" :key="item.product_id">
                <a-card hoverable style="width: 100%;">
                    <img slot="cover" alt="" v-bind:src="item.main_img"/>
                    <a-card-meta v-bind:title="item.title">
                    <template slot="description"><a-button @click="showDetail(item.product_id)">Product listing</a-button></template>
                    </a-card-meta>
                </a-card>
            </a-col>
        </a-row>
        <div style="width:100%;height:20px;"></div>
        <a-pagination 
            v-model="current"
            :total="total_rows"
            show-size-changer:page-size="pageSize"
            @change="onChange"
        >
        </a-pagination>
    </div>
</template>

<script>
// import {getProducts} from '@/network/prorequest';
import {getProducts} from '@/network/navigation';

export default {
    name: 'NodeprojectStores',
    components: {  },
    directives: {  },
    data() {
        return {
            current:1,
            total_rows:0,
            pageSize: 15,
            productData:[], 
        };
    },
    created(){
        getProducts(this.current,15)
        .then(res=>{
            if(res.header.code==200){
                this.productData = res.body.products;
                this.total_rows  =res.body.total_rows;
            }
        });
    },
    computed:{
        
    },
    mounted() {
        
    },
    methods: {
        showDetail(product_id){
            console.log(product_id);
            this.$router.push({path:'/home/productlisting',query:{product_id:product_id}})
        },
        onChange(current) {
            this.current = current;
            getProducts(this.current,15)
            .then(res=>{
                if(res.header.code==200){
                    this.productData = res.body.products;
                    this.total_rows  =res.body.total_rows;
                }
            });
        }
    },
};
</script>

<style  scoped>

</style>