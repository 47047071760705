import {request} from './request_loc'
import Cookies from 'js-cookie'
import qs from 'qs'

export function getProducts(page,rows_per_page=15,product_id='') {
    let maker_id = Cookies.get("maker_id");
    return request({
        url:'/products2/getProducts',
        params:{maker_id,page,rows_per_page,product_id},
    })
}